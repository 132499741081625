@keyframes on-hover-hand {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(0deg) scale(1.1);
  }

  30% {
    transform: rotate(15deg) scale(1.1);
  }

  50% {
    transform: rotate(-15deg) scale(1.1);
  }

  70% {
    transform: rotate(0deg) scale(1.1);
  }

  100% {
    transform: rotate(0deg) scale(1);
  }
}

.animated-selected-hand,
.animated-selected-hand-reverse {
  > img {
    &:hover {
      animation: on-hover-hand forwards 0.7s;
    }
  }
}
