@import '../../../styles/color';

// animations gradient
@keyframes Gradient {
  0% {
    background-attachment: fixed;
    background-position: 0% 50%;
  }

  50% {
    background-attachment: fixed;
    background-position: 100% 50%;
  }

  100% {
    background-attachment: fixed;
    background-position: 0% 50%;
  }
}

.dev-background {
  animation: Gradient 15s ease infinite;
  background: $bg-gradient;
  background-size: $bg-gradient-size;

  height: 100%;
  min-height: 100vh;
}

.dev-app,
.app {
  background-color: $white-with-opacity;
}

.dev-app {
  border-radius: 25px;
}
