$animation-duration: 6s;

@keyframes fade {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }

  7% {
    opacity: 1;
    transform: rotate(40deg);
  }

  100% {
    opacity: 1;
    transform: rotate(40deg);
  }
}

@keyframes fade-reverse {
  0% {
    opacity: 0;
    transform: rotate(0deg) scaleX(-1);
  }

  7% {
    opacity: 1;
    transform: rotate(-40deg) scaleX(-1);
  }

  100% {
    opacity: 1;
    transform: rotate(-40deg) scaleX(-1);
  }
}

.animated-hands {

  animation: fade infinite $animation-duration;

  &-reverse {
    animation: fade-reverse infinite $animation-duration;
  }
}
