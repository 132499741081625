// Add custom variables
/*
	Add custom variables
	See '~bootswatch/dist/materia/variables' file and change what you want here before imports
*/

// Buttons 
$btn-padding-y: 0.65em;
$btn-padding-x: 0.65em;

$btn-white-space: nowrap;

$btn-border-radius: 0.25rem;

// Disable color gradients
$enable-gradients: false;

// Tables
$table-border-width: 0;
