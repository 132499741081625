$hand-nth-padding: 7%;
$hand-transform-scale-up-down: 0;

.with-loading-hands:nth-child(2) {
  padding-bottom: $hand-nth-padding;

  >* img {
    transform: scale(1 + $hand-transform-scale-up-down);
  }
}

.with-loading-hands:nth-child(1),
.with-loading-hands:nth-child(3) {
  padding-top: $hand-nth-padding;

  >* img {
    transform: scale(1 - $hand-transform-scale-up-down);
  }
}
