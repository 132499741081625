$animation-default-time: 2s;

$opacity-visible: 1;
$opacity-none: 0;
$opacity-preview: 0.7;

$scale-x-reverse: -1;

$default-up-down: 0.25;
$scale-up: 1 + $default-up-down;
$scale-down: 1 - $default-up-down;

$start-rotation: 0deg;

$selected-rotation: 40deg;
$selected-rotation-reverse: -$selected-rotation;

$preview-rotation: 20deg;
$preview-rotation-reverse: -$preview-rotation;

@keyframes selected {
  0% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation);
  }
}

@keyframes selected-reverse {
  0% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation) scaleX($scale-x-reverse);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse);
  }
}

@keyframes winner {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scale($scale-up);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scale($scale-up);
  }
}

@keyframes winner-reverse {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scaleX($scale-x-reverse);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse) scale($scale-up);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse) scale($scale-up);
  }
}

@keyframes looser {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scale($scale-down);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scale($scale-down);
  }
}

@keyframes looser-reverse {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation) scaleX($scale-x-reverse);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse) scale($scale-down);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse) scale($scale-down);
  }
}

@keyframes draw {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($start-rotation);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($start-rotation);
  }
}

@keyframes draw-reverse {
  0% {
    opacity: $opacity-visible;
    transform: rotate($selected-rotation-reverse) scaleX($scale-x-reverse);
  }

  7% {
    opacity: $opacity-visible;
    transform: rotate($start-rotation) scaleX($scale-x-reverse);
  }

  100% {
    opacity: $opacity-visible;
    transform: rotate($start-rotation) scaleX($scale-x-reverse);
  }
}

@keyframes show-normal {
  from {
    opacity: $opacity-none;
  }

  to {
    opacity: $opacity-visible;

  }
}

@keyframes show-reverse {
  from {
    opacity: $opacity-none;
    transform: scaleX($scale-x-reverse);
  }

  to {
    opacity: $opacity-visible;
    transform: scaleX($scale-x-reverse);
  }
}

@keyframes preview {
  0% {
    opacity: $opacity-preview;
    transform: rotate($start-rotation);
  }

  7% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation);
  }

  100% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation);
  }
}

@keyframes preview-reverse {
  0% {
    opacity: $opacity-preview;
    transform: rotate($start-rotation) scaleX($scale-x-reverse);
  }

  7% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation-reverse) scaleX($scale-x-reverse);
  }

  100% {
    opacity: $opacity-preview;
    transform: rotate($preview-rotation-reverse) scaleX($scale-x-reverse);
  }
}

.animated-selected-hand {

  animation: show-normal forwards $animation-default-time;

  &-winner {
    animation: winner forwards $animation-default-time;
  }

  &-looser {
    animation: looser forwards $animation-default-time;
  }

  &-draw {
    animation: draw forwards $animation-default-time;
  }

  &-selected {
    animation: selected forwards $animation-default-time;
  }

  &-preview {
    animation: preview forwards $animation-default-time;
  }

  &-reverse {
    animation: show-reverse forwards $animation-default-time;

    &-winner {
      animation: winner-reverse forwards $animation-default-time;
    }

    &-looser {
      animation: looser-reverse forwards $animation-default-time;
    }

    &-draw {
      animation: draw-reverse forwards $animation-default-time;
    }

    &-selected {
      animation: selected-reverse forwards $animation-default-time;
    }

    &-preview {
      animation: preview-reverse forwards $animation-default-time;
    }
  }
}
