@import './bootstrap/custom';

html {
  overflow-x: hidden;
}

body {
  background-color: transparent;
  height: 100%;
  margin: 0;
  margin: 0;
}
